import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../templates/Layout";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";


export const pageQuery = graphql`
    query Tips {
        mdx(frontmatter: {title: {eq: "Tips"}}) {
            body
            frontmatter {
              title
            }
        }

        byMonth: allMdx(
            filter: {fileAbsolutePath: {regex: "data/tipsByMonth/"}}
            sort: {fields: frontmatter___order, order: ASC}
        ) {
            edges {
                node {
                    body
                    frontmatter {
                        title
                        order
                    }
                }
            }
        }
    }
`


class Tips extends React.Component {
    render() {
        const data = this.props.data.mdx;
        const byMonth = this.props.data.byMonth.edges;

        var today = new Date();
        var month = String(today.getMonth()).padStart(2, '0');

        return (
            <Layout
                title={data.frontmatter.title}
                location={this.props.location}
            >
                <MDXRenderer>{data.body}</MDXRenderer>

                <Tabs
                    defaultIndex={month}
                    id="byMonth"
                >
                    <TabList>
                        {byMonth.map(({ node }) => {
                            return (
                                <Tab key={node.frontmatter.order}>
                                    {node.frontmatter.title}
                                </Tab>
                            )
                        })}
                    </TabList>

                    {byMonth.map(({ node }) => {
                        return (
                            <TabPanel key={node.frontmatter.order}>
                                <MDXRenderer>{node.body}</MDXRenderer>
                            </TabPanel>
                        )
                    })}
                </Tabs>
            </Layout>
        )
    }
}
        
export default Tips